import firebase from "@firebase/app"
require("firebase/firestore")
require('firebase/auth')
require('firebase/storage')
require("firebase/functions");
require("firebase/analytics")

let config = {
  apiKey: "AIzaSyBbD1yJ0svxQodhHOttCcbJEjgg6x4qJtU",
    authDomain: "ciardweb.firebaseapp.com",
    databaseURL: "https://ciardweb.firebaseio.com",
    projectId: "ciardweb",
    storageBucket: "ciardweb.appspot.com",
    messagingSenderId: "831840540537",
    appId: "1:831840540537:web:2a10fe723b30306acd81a8",
    measurementId: "G-QTSSRP57S0"
}
 
firebase.initializeApp(config)
firebase.analytics()

const db = firebase.firestore()
const _firestore = firebase.firestore
const auth = firebase.auth()
const functions = firebase.functions()
const _auth = firebase.auth
const storage = firebase.storage()
const ColeccionLanding = db.collection('Landing').doc('Contenido')
const ColeccionLandingImagenes = db.collection('Landing').doc('Imágenes')
const Colección_blog = db.collection('Landing').doc('Contenido').collection('Blog')
const Colección_etiquetas = db.collection('Landing').doc('Contenido').collection('Etiquetas')
const enviar_correo_electronico = functions.httpsCallable('correo_electronico-enviar')

export {
  db,
  functions,
  auth,
  _auth,
  storage,
  firebase,
  ColeccionLanding,
  _firestore,
  ColeccionLandingImagenes,
  enviar_correo_electronico,
  Colección_blog,
Colección_etiquetas
}