import Vue from 'vue'
import vuetify from './plugins/vuetify.js'
import App from './App.vue'
import router from './router'
import store from './store/store'
//import './registerServiceWorker'
import {mapGetters, mapState} from 'vuex'
import '@fortawesome/fontawesome-free/css/all.css';

Vue.config.productionTip = false
let app = null

Vue.mixin({
	data: ()=>({
		requerido: v => !!v || 'Este campo es requerido',
		min_8: value => value && value.length >= 8 || 'Mínimo 8 caracteres',
		tam_9: value => value && value.length==9 || 'Se necesitan 9 caracteres',
		correo_electronico: v => /.+@.+\..+/.test(v) || 'El correo debe ser válido.',
		tam_11: value => value && value.length==11 || 'Se necesitan 11 caracteres',
		max_140: value => value && value.length<=140|| 'Máximo 140 caracteres',

	}),
	computed:{
		...mapGetters({
			Contenido: 'Landing_set/contenido'
		}),
		...mapState({
			cargando: state => state.Landing_set.cargando,
			Ayudas : state => state.Ayudas,
			Contenido: state=>state.Landing_set.contenido,
		}),
		arbitraje(){
			return this.$store.getters['Landing_set/contenido_especifico'] ({pagina: 'página principal', nudo: 'arbitraje'}) 
		},
		consejo_superior_de_arbitraje(){
			return this.$store.getters['Landing_set/contenido_especifico']({pagina: 'página principal', nudo: 'consejo_superior_de_arbitraje'})
		},
		consejo_de_etica(){
			return this.$store.getters['Landing_set/contenido_especifico']({pagina: 'página principal', nudo: 'consejo_de_etica'})
		},
		secretaria_general(){
			return this.$store.getters['Landing_set/contenido_especifico']({pagina: 'página principal', nudo: 'secretaria_general'})
		},
		encabezado(){
			return this.$store.getters['Landing_set/contenido_especifico']({pagina: 'página principal', nudo: 'encabezado'})
		},
		contacto(){
			return this.$store.getters['Landing_set/contenido_especifico']({pagina: 'página principal', nudo: 'contacto'})
		},
		servicios(){
			return this.$store.getters['Landing_set/contenido_especifico']({pagina: 'página principal', nudo: 'servicios'})
		},
		pie_de_pagina(){
			return this.$store.getters['Landing_set/contenido_especifico']({pagina: 'página principal', nudo: 'pie_de_pagina'})
		},
		convenio(){
			return this.$store.getters['Landing_set/contenido_especifico']({pagina: 'página principal', nudo: 'convenio'})
		},
		quienes_somos(){
			return this.$store.getters['Landing_set/contenido_especifico']({pagina: 'página principal', nudo: 'quienes_somos'})
		},
		logo(){
			return this.$store.getters['Landing_set/elementos']('logo')			
		},
		menu(){
			return this.$store.getters['Landing_set/elementos']('menu')			
		},
		arbitraje_encabezado(){
			return this.$store.getters['Landing_set/contenido_especifico']({pagina: 'página arbitraje', nudo: 'encabezado'})
		},
		arbitraje_registro_de_árbitros(){
			return this.$store.getters['Landing_set/contenido_especifico']({pagina: 'página arbitraje', nudo: 'registro_de_árbitros'})
		},
		arbitraje_normativa(){
			return this.$store.getters['Landing_set/contenido_especifico']({pagina: 'página arbitraje', nudo: 'normativa'})
		},
		
		adjudicadores_encabezado(){
			return this.$store.getters['Landing_set/contenido_especifico']({pagina: 'página registro de adjudicadores', nudo: 'encabezado'})
		},
		adjudicadores_registro_de_árbitros(){
			return this.$store.getters['Landing_set/contenido_especifico']({pagina: 'página registro de adjudicadores', nudo: 'registro_de_árbitros'})
		},
		adjudicadores_normativa(){
			return this.$store.getters['Landing_set/contenido_especifico']({pagina: 'página registro de adjudicadores', nudo: 'normativa'})
		},
		disputas_encabezado(){
			return this.$store.getters['Landing_set/contenido_especifico']({pagina: 'página junta de resolución de disputas', nudo: 'encabezado'})
		},
		disputas_normativa(){
			return this.$store.getters['Landing_set/contenido_especifico']({pagina: 'página junta de resolución de disputas', nudo: 'normativa'})
		},
		
		nosotros(){
			return this.$store.getters['Landing_set/contenido_especifico']({pagina: 'página nosotros', nudo: 'encabezado'})
		},
		ciardacademy(){
			return this.$store.getters['Landing_set/contenido_especifico']({pagina: 'ciardacademy', nudo: 'encabezado'})
		},
		ciardacademy_capacitacion(){
			return this.$store.getters['Landing_set/contenido_especifico']({pagina: 'ciardacademy', nudo: 'capacitacion'})
		},
		portaldetransparencia(){
			return this.$store.getters['Landing_set/contenido_especifico']({pagina: 'página panel de transparencia', nudo: 'encabezado'})
		},
		cuerpo_portaldetransparencia(){
			return this.$store.getters['Landing_set/contenido_especifico']({pagina: 'página panel de transparencia', nudo: 'cuerpoportal'})

		},
		mediacion_encabezado(){
			return this.$store.getters['Landing_set/contenido_especifico']({pagina: 'página de mediación', nudo: 'encabezado'})
		},
		mediacion_normativa(){
			return this.$store.getters['Landing_set/contenido_especifico']({pagina: 'página de mediación',  nudo: 'normativa'})
		},
		mediacion_registro_de_árbitros(){
			return this.$store.getters['Landing_set/contenido_especifico']({pagina: 'registro de mediación', nudo: 'registro_de_árbitros'})
		},
		
		laudos_encabezado(){
			return this.$store.getters['Landing_set/contenido_especifico']({pagina: 'página de laudos', nudo: 'encabezado'})
		},
		sanciones_encabezado(){
			return this.$store.getters['Landing_set/contenido_especifico']({pagina: 'página de sanciones', nudo: 'encabezado'})
		},
		arbitros_encabezado(){
			return this.$store.getters['Landing_set/contenido_especifico']({pagina: 'página de arbitros', nudo: 'encabezado'})
		},
	}
})

if(!app){
	app = new Vue({
		router,
		store,
		vuetify,
		render: h => h(App)
	}).$mount('#app')
}