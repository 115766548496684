<template lang='pug'>
v-app
  v-app-bar( app color="#003452" scroll-target="#scrolling-techniques-7" inverted-scroll ).d-none.d-md-block
    
    v-img(:src='logo? logo.logo_blanco : ""' contain width='50px' height='30px')
    v-spacer
    template( v-for='item, i in menu' )
      v-btn.d-none.d-sm-flex(color='white ' plain text :disabled='!item.vínculo' :href='item.vínculo' v-html='item.texto') 
    // Botón Servicios con submenú escroleable
    v-menu(offset-y  close-on-content-click="true")
      template(v-slot:activator="{ on, attrs }")
        v-btn(text v-bind="attrs" v-on="on"
        color="white"
        plain
      ) Servicios
      v-list
        v-list-item(:href="'/arbitraje'")
          v-list-item-title Arbitraje
        v-list-item(:href="'/mediacion'")
          v-list-item-title Mediación y Conciliación
        v-list-item(:href="'/junta-de-resolucion-de-disputas'")
          v-list-item-title Junta de Resolución de Disputas
          
  v-app-bar( app color="#003452" height='70px'  scroll-target="#scrolling-techniques-7" inverted-scroll ).d-md-none
    v-row.align-center
      v-col
        v-img(:src='logo? logo.logo_blanco : ""' contain width='80px' height='60px')

      v-col( align='end' justify='center')
        v-btn( @click='drawer=true' color='white' icon)
          v-icon fa-solid fa-bars
  v-navigation-drawer(temporary v-model="drawer" app style="background-color: #003452")
    router-link(tile flat :to='{name: "inicio"}')
      v-img(:src='logo? logo.logo : ""' contain height='100px').mt-5.mb-5
    v-list(nav='', dense='').azul
      v-list-item(v-for='item, i of  menu'  :key = 'i' :disabled='!item.vínculo' :to='item.vínculo'  style='text-transform: unset')
        v-list-item-content
          v-list-item-title(v-html='item.texto').white--text
  
  v-sheet(
    id="scrolling-techniques-7"
    class="overflow-y-auto"
    :max-height="altura"
  )
    v-main
      v-container(fluid align='center').pa-0.azul
        v-col.py-0
          v-row.align-center.justify-center.pa-7
            router-link(tile flat :to='{name: "inicio"}')
                img(:src='logo? logo.logo : ""' contain height='130px').d-none.d-md-flex
                img(:src='logo? logo.logo : ""' contain height='100px').d-md-none
                
        v-container(fluid).py-0.d-none.d-md-block.py-2.align-center.justify-center
          v-row(align='center' justify-center style="margin-left: auto").align-center.justify-center
            template(v-for="(item, i) in menu")
              v-btn.d-none.d-md-flex(
                color="white"
                active-class="primary--text"
                plain
                :to="item.vínculo"
                :disabled="!item.vínculo"
                elevation-0
              )
                p(v-html="item.texto")

            // Botón Servicios con submenú escroleable
            v-menu(offset-y  close-on-content-click="true")
              template(v-slot:activator="{ on, attrs }")
                v-btn(text v-bind="attrs" v-on="on"
                color="white"
                active-class="primary--text"
                plain
                elevation-0
              ) Servicios
              v-list
                v-list-item(:href="'/arbitraje'")
                  v-list-item-title Arbitraje
                v-list-item(:href="'/mediacion'")
                  v-list-item-title Mediación y Conciliación
                v-list-item(:href="'/junta-de-resolucion-de-disputas'")
                  v-list-item-title Junta de Resolución de Disputas

      router-view
      //- PIE DE PAGINA
      v-container(fluid).azul
        v-col(cols=12 md=12)
          v-row.justify-center
            template(v-if='pie_de_pagina' v-for='{texto}, i of pie_de_pagina.redes')
              p.text-center.font-weight-light.white--text(v-html='texto').mr-4.d-none.d-md-block
              p.text-center.font-weight-light.white--text(v-html='texto' style="font-size: 10px").mr-4.d-md-none
          v-row.justify-center.mt-6(v-if='logo' style="padding-bottom: 15px")
            i(v-for='logo, i of logo.redes' :key='`Red${i}`' style="font-size: 20px; cursor: pointer; color: white; " :class='logo.icono' @click='redirigir_a(logo.vinculo)').mr-3.ml-3
      
          v-footer.align-items-center.justify-center(style="background-color: #003452").pt-2
            p.font-weight-light.white--text(style="font-size: 12px") {{`${año} - © Todos los derechos reservados`}}
  
  v-overlay(:value='cargando')
    v-progress-circular(indeterminate color="white")
  
  v-snackbar(v-model="Ayudas.snackbarShow" :timeout='3000')
    span {{ Ayudas.snackbarMessage }}
    v-btn(color='primary' text @click='Ayudas.snackbarShow=false') Ok
</template>

<script>
export default {
  methods: {
    redirigir_a(vinculo){
      window.open(vinculo, '_blank')
    },
  },
  data: () => ({
    año: new Date().getFullYear(),
    drawer: false,
    altura: window.innerHeight,
    opciones: {
      duration: 500,
      offset: 0,
      easing: 'easeInOutCubic'
    }
  }), 
  created() {
    this.$store.dispatch('Landing_set/obtener')
    this.$store.dispatch('Blog/obtener_todos')
  },
}
</script>
<style lang="sass">

  @font-face 
    font-family: 'EBGaramond'
    src: url("./assets/fonts/EBGaramond/EBGaramond08-Regular.otf") format("truetype")
    font-weight: 400

  @font-face 
    font-family: 'Poppins'
    src: url("./assets/fonts/Poppins/Poppins-Light.otf") format("truetype")
    font-weight: 400

  @font-face 
    font-family: 'Poppins'
    src: url("./assets/fonts/Poppins/Poppins-Regular.otf") format("truetype")
    font-weight: 500

  @font-face 
    font-family: 'Poppins'
    src: url("./assets/fonts/Poppins/Poppins-Medium.otf") format("truetype")
    font-weight: 600

  @font-face 
    font-family: 'Poppins'
    src: url("./assets/fonts/Poppins/Poppins-SemiBold.otf") format("truetype")
    font-weight: 700

  @font-face 
    font-family: 'Poppins'
    src: url("./assets/fonts/Poppins/Poppins-Bold.otf") format("truetype")
    font-weight: 800

  h1,h2,h3,h4,h5,h6,.display-1, .display-2, .display-3, .display-4, .title
  p, span, a, button
    font-family: 'Poppins', sans-serif
  html
    overflow: hidden
  .vh100
    height: 100vh
  .subrayado
    height: 2px
    margin-bottom: 2px
    background-color: #d5a670 !important
  p
    margin-bottom: 0 !important
  .v-app-bar--is-scrolled
    background-color: transparent
  .v-app-bar--hide-shadow
    background-color: white!important
  .azul
    background-color: #003452
  .doradociard
    background-color: #F1ddb0 
  .descripcion
    color: #7c797d
  .lineamedia
    height:100%
    margin-bottom: 2px
    background-color: #d5a670 !important
  .lineamedia_arbitraje
    height:100%
    margin-bottom: 2px
    background-color: #d5a670 !important
</style>