import Vue from 'vue'
import Vuex from 'vuex'
import Landing_set from './components/landing_set'
import Ayudas from './components/Ayudas'
import Blog from './components/Blog'
import Etiquetas from './components/Etiquetas'
Vue.use(Vuex)
export default new Vuex.Store({
  modules: {
    Ayudas,
    Landing_set,
    Blog,
    Etiquetas,
  }
})
