import Vue from 'vue'
import Vuex from 'vuex'
import {ColeccionLanding, ColeccionLandingImagenes} from '@/FirebaseConfig'
Vue.use(Vuex)
export default {
    namespaced:true,
    state:{
      contenido: {
        Bienvenido: { título: 'bienvenido' },
      },
      cargando: true,
      lista_de_imagenes: [],
    
    },
    actions:{
      obtener:({state})=>{
        if (!state.cargando) return 
        state.cargando = true
        ColeccionLanding.onSnapshot(doc=>{
          if(doc.exists) state.contenido = doc.data()
          state.cargando = false
        })
        ColeccionLandingImagenes.onSnapshot(doc=>{
          if(doc.exists) state.lista_de_imagenes = doc.data().lista
        })
      },

     
    },
    getters:{
      contenido_especifico: ({contenido}) => ({pagina, nudo}) =>  contenido[pagina] ? contenido[pagina][nudo] : [],
      elementos: ({contenido}) => elemento => contenido ? contenido[elemento] : [],
      contenido: ({contenido}) => contenido,

      imágenes: state => state.lista_de_imagenes

    }
}
