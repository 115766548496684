import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
export default {
  namespaced:true,
  state: {
    snackbarMessage: '',
    cargando: false,
    snackbarShow: false,
    reglas: {
      required: value => !!value || 'Requerido.',
      min: value => value && value.length >= 8 || 'Min 8 characters',
      emailMatch: () => ('The email and password you entered don\'t match'),
      email_valido: v => /.+@.+\..+/.test(v) || 'El correo debe ser válido'
    },
  },
  mutations: {
    launchSnackbar: (state, message)=>{
      state.snackbarMessage = message 
      state.snackbarShow = true
    },
    cargando: (state, estado)=>{
      state.cargando = estado
    }
  }
}