import Vue from 'vue'
import Router from 'vue-router'
import goTo from 'vuetify/es5/services/goto'

Vue.use( Router )
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    { 
      path: '/',
      name: 'inicio',
      component: () => import('./app/landing_page/index'),
   
    },
    {
      path: '/nosotros',
      name: 'nosotros',
      component: () => import('./app/nosotros/index'),
   
    },
    {
      path: '/ciardacademy',
      name: 'ciardacademy',
      component: () => import('./app/ciardacademy/index'),
   
    },
    {
      path: '/arbitraje',
      name: 'arbitraje',
      component: () => import('./app/arbitraje/index'),
   
    },
    {
      path: '/mediacion',
      name: 'mediacion',
      component: () => import('./app/mediacion/index'),
    },
    {
      path: '/portal_de_transparencia_laudos',
      name: 'laudos',
      component: () => import('./app/laudos/index'),
    },
    {
      path: '/portal_de_transparencia_sanciones',
      name: 'sanciones',
      component: () => import('./app/sanciones/index'),
    },
    {
      path: '/portal_de_transparencia_arbitros',
      name: 'sanciones',
      component: () => import('./app/arbitros/index'),
    },
    {
      path: '/registro_de_arbitros',
      name: 'registro_de_arbitros',
      component: () => import('./app/mediacion/registro_de_arbitros'),
    },
    {
      path: '/registro_de_arbitros',
      name: 'registro_de_arbitros',
      component: () => import('./app/disputas/registro_de_arbitros'),
    },
    {
      path: '/junta-de-resolucion-de-disputas',
      name: 'disputas',
      component: () => import('./app/disputas/index'),
    },
    {
      path: '/contacto',
      name: 'contacto',
      component: () => import('./app/arbitraje/contacto'),
   
    },
    {
      path: '/portaldetransparencia',
      name: 'portaldetransparencia',
      component: () => import('./app/portaldetransparencia/index'),
   
    },
    {
      path: '/eventos',
      name: 'eventos',
      component: ()=>import('./app/eventos/eventos.vue'),
    },
    {
      path: '/eventos/evento/:idEvento?',
      name: 'entradaBlog',
      component: ()=>import('./app/eventos/entradaDeBlog.vue'),
    },
    {
      path: '/capacitaciones',
      name: 'capacitaciones',
      component: ()=>import('./app/capacitaciones/capacitaciones.vue'),
    }

  ]
})
router.beforeEach(async (to,from,next)=> {
  next()
  if(to.hash) goTo(to.hash)

})
router.scrollBehavior = (to, from, savedPosition) => {
  let scrollTo = 0
  if (to.hash)  scrollTo = to.hash
  else if (savedPosition)  scrollTo = savedPosition.y
  return goTo(scrollTo)
}
export default router