import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import es from 'vuetify/es5/locale/es'

Vue.use(Vuetify)

Vue.component('my-component', {
  methods: {
    changeLocale () {
      this.$vuetify.lang.current = 'es'
    },
  },
})
export default new Vuetify({
  icons: {
      iconfont: 'fa',
  },
  lang: {
      locales: { es },
      current: 'es'
  },
  theme: {
    themes: {
      light: {
        primary:'#d5a670',
        secondary: '#e2b26d',
        gris:'#56534e',
        negro:'#333230',
        azul: '#003452',
        amarrilloClaro: '#f4dda9'
      },
    },
  }
})