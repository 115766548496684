import Vue from 'vue'
import Vuex from 'vuex'
import { Colección_blog } from '@/FirebaseConfig'
Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    registro: [],
    cantidad_de_registros: 0,
    entrada: {
      contenido: '',
      título: '',
      subtítulo: '',
      portada: '',
      imágenes: [],
      etiquetas: []
    },
  },
  mutations: {
    setItem: (state, item)=>{
      state.entrada = item
    }
  },
  actions: {
    obtener_por_slug: async( context, _slug)=> {
      const coleccion = await Colección_blog.where('slug', '==', _slug).get()
      return coleccion.empty ? [] : coleccion.docs.map( doc => ({...doc.data(), id: doc.id, creado_el: doc.data().creado_el.toDate() }) )[0]
    },
    obtener_todos: ({state})=>{
      Colección_blog.orderBy('creado_el', 'asc').onSnapshot(({docs, size})=> {
        const registro = []
        docs.map( doc => registro.push({...doc.data(), id: doc.id, creado_el: doc.data().creado_el.toDate() }))
        state.registro = registro
        state.cantidad_de_registros = size
      })
    },
    obtener_por_id_de_blog: async( {state, dispatch}, _id ) =>  {
      if(state.registro.length==0) await dispatch('obtener_todos')
      state.entrada = await dispatch('obtener_por_id', _id)
    },
    obtener_por_id: async( {state}, _id ) =>  {
      Colección_blog.doc(_id).get().then(doc=>{
        state.entrada = {...doc.data(), id: doc.id}
      })
    },
    obtener_por_slug_de_blog: async ({state, dispatch}, _slug) => {
      if(state.registro.length==0) await dispatch('obtener_todos')
      state.entrada = await dispatch('obtener_por_slug', _slug)
    },
  },
  getters: {
    registro: ({registro}) => registro,
    cantidad_de_registros: ({cantidad_de_registros}) => cantidad_de_registros,
    entrada: ({entrada}) => entrada || {},
    registro_de_entradas: ({registro})=>({filtro, etiqueta_filtro})=>{
      if(!etiqueta_filtro&&typeof etiqueta_filtro == 'boolean') {
        if(filtro=='') return registro
        else return registro.filter(({título})=>título.toUpperCase().includes(filtro.toUpperCase()))
      } else {
        if(filtro=='') return registro.filter(({etiquetas})=>etiquetas.some(({id})=>id==etiqueta_filtro))
        else return registro.filter(({etiquetas})=>etiquetas.some(({id})=>id==etiqueta_filtro)).filter(({título})=>título.toUpperCase().includes(filtro.toUpperCase()))
      }
    },
    registro_de_entradas_por_etiquetas: ({registro})=>(id_etiqueta)=>{
      return registro.filter(({etiquetas})=>etiquetas.some(({id})=>id==id_etiqueta)) 
    },
    registro_de_segundo_a_cuarto: ({registro})=>registro.slice(1, 5),
    registro_de_primero_a_segundo: ({registro})=>registro.slice(0, 2),
  },

}